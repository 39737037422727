const getURLVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
       const pair = vars[i].split('=');
       if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
       }
    }
    console.error('Variável da URL não encontrada: ' + variable);
    return null; // Retorna null se a variável não for encontrada
 };

 export { getURLVariable };