import React, { useContext, useState , useEffect } from "react";

/// React router dom
import { Routes, Route, Outlet, useLocation } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./pages/Dashboard/Home";

import Shoppers from "./pages/Customers";

import OrderHistory from "./pages/Dashboard/OrderHistory";

/// App
import AppProfile from "./pages/AppProfile";

/// Pages
import LockScreen from "./pages/LockScreen";
import { ThemeContext } from "../context/ThemeContext";
import { Link } from "react-router-dom";
import Product from "./pages/Product";
import FoodOrder from "./pages/FoodOrder"
import Order from "./pages/Order"
import Setting from "./pages/Setting";
import Menu from "./pages/Menu";
import Cart from "./pages/Dashboard/Cart";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./layouts/nav/Logout"

/* logo */
import logo from '../images/logo.png'

import { getProfile } from "../services/UserService";

const Markup = () => {

  const allroutes = [
    // { url: "", component: <Home /> },
    // { url: "dashboard", component: <Home /> },
    { url: "", component: <Shoppers /> },
    { url: "dashboard", component: <Shoppers /> },
    { url: "lojas", component: <Shoppers /> },
    { url: "produto", component: <Product /> },
    { url: "loja", component: <Menu /> },
    { url: "usuario", component: <AppProfile /> },
    { url: "pedidos", component: <FoodOrder /> },
    { url: "pedido", component: <Order /> },
    { url: "historico-de-pedidos", component: <OrderHistory /> },
    { url: "carrinho", component: <Cart /> },
    { url: "opcoes", component: <Setting /> }
  ];

  return (
    <>
      <Routes>
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />

    </>
  );
};

const Counter = (props) => {
  return (
    <>
      {
        props.qtd > 0 ?
          <span style={{width: 20,height: 20,position: 'fixed',backgroundColor: '#f54749',marginRight: -150,borderRadius: '100%',fontSize: 12,paddingTop: 2,color: 'white',}}>
            { props.qtd }
          </span>
        :null
      }
    </>
  )
}

function MainLayout() {
  let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
  const [ name ] = useState(dumbToken.name)
  const [qtd, setQtd] = useState(0)
  const [toggle, setToggle] = useState(false)
  const { menuToggle } = useContext(ThemeContext);
  const location = useLocation();
  const activeRoute = location.pathname.split('/'); // Obtém a última parte da URL como rota ativa

  useEffect(() => {
    if (localStorage.cart) {
      const cart = JSON.parse(localStorage.cart);
      let somaQuantidades = 0;
      if (cart.cart.itens && cart.cart.itens.length > 0) {
        cart.cart.itens.forEach(item => {
          if (item.hasOwnProperty('quantidade')) {
            somaQuantidades += item.quantidade;
          }
        });
      }
      setQtd(somaQuantidades);
    } else {
      setQtd(0);
    }
  }, []);

  useEffect(() => {
    if (!toggle) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const fetchAndSaveProfileId = async () => {
        if (!userDetails.profileId) {
          try {
            const response = await getProfile();
            if (response.status === 200) {
              const profileData = response.data;
              userDetails.profileId = profileData.id;
              localStorage.setItem('userDetails', JSON.stringify(userDetails));
            } else {
              console.error('Erro ao obter o perfil: Status', response.status);
            }
          } catch (error) {
            console.error('Erro ao obter o perfil:', error);
          }
        }
      };
      fetchAndSaveProfileId();
      setToggle(true);
    }
  }, [toggle]);

  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      {/* <Nav /> */}
      <nav style={{ width: '100%', position: 'fixed', top: 0, zIndex: 99 }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '6px 0', backgroundColor: 'rgb(18, 176, 81)' }}>
          <img src={logo} style={{ width: 150 }} />
        </div>

        <ul className="navbar-nav"
          style={
            {
              width: 90,
              height: 23,
              position: 'fixed',
              right: '5px',
              top: 5
            }
          }>
          <li>
            <Dropdown className=" header-profile2 ">
              <Dropdown.Toggle as="a" className={`nav-link  cursor-pointer `} id="droptoggle1" style={{padding: 1}}>
                <div id="user" className="d-flex align-items-center">
                  <div className="d-flex flex-column align-items-center gap-1 mt-3 text-white" style={{width: '100%'}}>
                    <div>
                      <h6 className="font-w500 mb-0 ms-2 text-white"><i className="la la-bi bi-person-circle " style={{margin: '2px 6px 0'}} /></h6>
                    </div>
                    <div>
                      <h6 className="font-w500 mb-0 ms-2 text-white">{name}</h6>
                    </div>
                    <i className="fas fa-chevron-down"></i>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-end">
                <Link to="./usuario" className="dropdown-item ai-icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                  <span className="ms-2">Perfil</span>
                </Link>
                <Link to="./opcoes" className="dropdown-item ai-icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                  <span className="ms-2">Configurações </span>
                </Link>
                <LogoutPage />
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>

        <div id="menuzito" style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '6px 0', gap: '0.4rem', backgroundColor: 'rgb(249 249 249)' }}>
          <Link to={"/dashboard"} style={activeRoute.includes('dashboard') || activeRoute.includes('lojas') || activeRoute.includes('loja') ? { backgroundColor: 'rgb(214 255 101)' } : {}}>
            <i className="bi bi-grid" />
            <span>início</span>
          </Link>
          <Link to={"/pedidos"} style={activeRoute.includes('pedidos') ? { backgroundColor: 'rgb(214 255 101)' } : {}}>
            <i className="bi bi-calendar-check" />
            <span>pedidos</span>
          </Link>
          <Link to={"/carrinho"} style={activeRoute.includes('carrinho') ? { backgroundColor: 'rgb(214 255 101)' } : {}}>
            <i className="bi bi-basket2" />
            <span>carrinho</span>
          </Link>
          <Counter qtd={qtd}/>
        </div>
      </nav>

      <div className="content-body" style={{ minHeight: window.screen.height - 45, margin: 0, paddingTop: 125 }}>
        <div className="container mr-auto ml-auto" style={{ maxWidth: 950 }}>
          <Outlet/>
        </div>
      </div>
      <Footer />
    </div>
  )

};

export default Markup;
