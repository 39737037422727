import axios from 'axios';


export function productsList(id, page, PerPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id,
        "Page": page,
        "PerPage": PerPage
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductsRequest`,
        postData, config,
    );
}

export function getProduct(id) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "ProductId": id
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductRequest`,
        postData, config,
    );
}

export function shoppersList(page, PerPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Page": page,
        "PageSize": PerPage,
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreByFiltersCommand`,
        postData, config,
    );
}

export function ordersList(page, PerPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Page": page,
        "PerPage": PerPage
      }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrdersByFilterRequest`,
        postData, config,
    );
}

export function ShopperRequest(id) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreRequest`,
        postData, config,
    );
}

export function productsShop( id , page, perPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "StoreId": id,
        "Page": page,
        "PerPage": perPage
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductsRequest`,
        postData, config,
    );
}

export function filterShops(Name, City, Street, Neighborhood, Page, PageSize) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data;
    
    const config = {
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    };

    const postData = {};

    if (Name) postData.Name = Name;
    if (City) postData.City = City;
    if (Street) postData.Street = Street;
    if (Neighborhood) postData.Neighborhood = Neighborhood;

    postData.Page = Page? Page : 1; 
    postData.PageSize = PageSize? PageSize : 0; 

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreByFiltersCommand`,
        postData, config
    );
}