import axios from 'axios';

export function ordersList(page, PerPage) {
    
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "Page": page,
        "PerPage": PerPage
      }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrdersByFilterRequest`,
        postData, config,
    );
}

export function createOrder(CustomerId, ShippingAddressId , StoreId , OrderItems , PaymentMethodId , DeliveryRate , Discount) {
    
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "customerId": CustomerId,
        "shippingAddressId": ShippingAddressId,
        "storeId": StoreId,
        "orderItems": OrderItems,
        "paymentMethodId": PaymentMethodId,
        "deliveryRate": DeliveryRate,
        "discount": Discount
      }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/CreateOrderCommand`,
        postData, config,
    );
}

export function ordersListMain( page ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "CustomerId": dumbToken.profileId,
        "Page": page
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrdersByFilterRequest`,
        postData, config,
    );
}

export function RetrieveOrder(id) {
    
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "OrderId": id
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrderRequest`,
        postData, config,
    );
}