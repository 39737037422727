export function CreateCart(loja, status) {
    localStorage.setItem('cart',
        JSON.stringify(
            {
                loja: loja,
                status: status,
                itens: []
            }
        )
    )
}

const CartAdd = (data) => {
    let cartDummy = JSON.parse(localStorage.getItem('cart'))
    if (!cartDummy.cart.itens || cartDummy.cart.itens.length === 0) {
        cartDummy.cart.itens = [{ "produto": data,  "quantidade": 1 }];
    } else {
        const itemIndex = cartDummy.cart.itens.findIndex(item => item.produto.id === data.id);
        if (itemIndex !== -1) {
            cartDummy.cart.itens[itemIndex].quantidade++;
        } else {
            cartDummy.cart.itens.push({ "produto": data, "quantidade": 1 });
        }
    }
    return(cartDummy)
};

const CartRmv = (data) => {
    let cartDummy = JSON.parse(localStorage.getItem('cart'));
    if (!cartDummy.cart.itens || cartDummy.cart.itens.length === 0) {
        cartDummy.cart.itens = [{ "produto": data, "quantidade": 1 }];
    } else {
        const itemIndex = cartDummy.cart.itens.findIndex(item => item.produto.id === data.id);
        if (itemIndex !== -1) {
            cartDummy.cart.itens[itemIndex].quantidade--;
            cartDummy.cart.itens = cartDummy.cart.itens.filter(item => item.quantidade > 0);
        } else {
            cartDummy.cart.itens.push({ "produto": data, "quantidade": 1 });
        }
    }
    return cartDummy;
};

export { CartRmv , CartAdd }
